import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Title } from "./styledComponents"

export default function TopImage({ topImage, title }) {
  return (
    <div className="flex flex-wrap justify-center">
    <div className="w-full sm:h-[800px] md:h-[600px] lg:h-[600px] xl:h-[600px] 2xl:h-[600px] h-[400px] inline-block overflow-hidden">
      <GatsbyImage
        className="w-full sm:h-[800px] md:h-[600px] lg:h-[600px] xl:h-[600px] 2xl:h-[600px] h-[400px] brightness-50 "
        image={topImage}
        alt="bla"
      />
      <Title className="absolute z-10 sm:top-[500px] top-[300px] left-10 right-10 text-white">{title}</Title>
    </div>
    </div>
  )
}
