import React from "react"
import { graphql, Link as GatsbyLink } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Seo from "../components/seo"
import Layout from "../components/layout"
import TopImage from "../components/topImage"

export default function Photo({ data }: { data: any }) {
  const files = data.allMarkdownRemark.nodes
  const topImage = data.markdownRemark.topImage.childImageSharp.gatsbyImageData
  return (
    <Layout>
      <Seo title="Gallery" />
      <TopImage topImage={topImage} title={data.markdownRemark.frontmatter.title}/>
      <div className="container mx-auto w-9/12 pt-10">
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-6 ">
          {files.map((file: any) => (
            <GatsbyLink to={"/gallery/" + file.frontmatter.path} key={file.frontmatter.path}>
              <div className="bg-white shadow-2xl rounded-3xl mb-5">
                <GatsbyImage
                  className="rounded-t-2xl flex items-center w-full aspect-video ..."
                  image={file.featuredImg.childImageSharp.gatsbyImageData}
                  alt="bla"
                />
                <div className="p-5">
                  <h5 className="text-gray-900 font-bold text-lg tracking-tight mb-3">
                    {file.frontmatter.title}
                  </h5>
                  <GatsbyLink
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center"
                    to={"/gallery/" + file.frontmatter.path}
                  >
                    See all
                  </GatsbyLink>
                </div>
              </div>
            </GatsbyLink>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ImagesForGallery {
    allMarkdownRemark(
      sort: { order: DESC, fields: frontmatter___date }
      filter: { fileAbsolutePath: { regex: "/gallery/" } }
    ) {
      nodes {
        frontmatter {
          path
          title
        }
        featuredImg {
          childImageSharp {
            gatsbyImageData(
              transformOptions: { fit: COVER, cropFocus: CENTER }
              placeholder: BLURRED
            )
          }
        }
      }
    }
    markdownRemark(fileAbsolutePath: { regex: "/gallMainPage/" }) {
      frontmatter {
        title
      }
      topImage {
        childImageSharp {
          gatsbyImageData(
            transformOptions: { cropFocus: SOUTHEAST, fit: CONTAIN }
            placeholder: BLURRED
            layout: FULL_WIDTH
          )
        }
      }
    }
  }
`
